import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styles from '../../components/BlogTeaser/BlogTeaser.module.scss';
import image1 from '../../images/SB296_Schubert.png';

const AktuellInhaltPage = () => (
  <Layout headerWhite>
    <SEO title="Aktuelles" />
    <Section>
      <Container>
        <Headline subtitle="Praxis für Orthopädie">Eröffnung</Headline>
        <Row className="justify-content-md-center">
            <Col xs="12" md="10">
            <p style={{textAlign:'center'}}>
            Am 1.1.2021 feiern wir Eröffnung!
            <br/>
            Mitten in Bochum am Dr.-Ruer-Platz Nr. 1, unter uns ein Parkhaus mit über 300 Stellplätzen mit barrierefreiem Zugang zur Praxis, einem Wartebereich mit Minibar, kostenlosen Zugang zum WLAN Netz und HD Flachbild TV.
            <br/>
            <br/>
            Ich freue mich besonders,
            <br/>
            mein klassisches Spektrum der Orthopädie mit dem Zugang von Fr Dr. Kostka mit ihrem integrativen Konzept der Ernährungs- und Schmerztherapie in den Räumlichkeiten auf der 3. Etage ergänzen zu können.
            <br/>
            Wir wünschen Ihnen eine besinnliche Weihnachtszeit
            und würden uns freuen,
            Sie im neuen Jahr begrüßen zu dürfen.
            <br/>
            <br/>
            Herzlichst Ihr Dr. Burkhard Schubert
            und sein Team
            <br/>
            <br/>
            P.S. Eine Terminvergabe ist ab sofort möglich: telefonisch, online oder per E mail.
            </p>
            </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AktuellInhaltPage;
